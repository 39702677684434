@use 'components/shared/core.scss' as *;

.rate-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.rate-container-label {
    font-size: $size-3xs;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    color: $grey-dark;
    margin-right: $size-xxs;
}

.location-details {
    .card-section {
        box-sizing: border-box;
        padding-left: $size-small;
        padding-right: $size-small;
        &.cash{ 
            padding-left: $size-xl;
            padding-right: $size-xl;
        }
    }
    .card-section-label-bar{
        text-align: center;
        font-size: $size-xxs;
        font-weight: bold;
        box-sizing: border-box;
    }
    .red-text {
        color: $red;
    }
}

.location-details-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.location-details-button-container {
    margin-bottom: $size-xxs;
}

.contact-checks-errors {
    height: $size-font-large;
    line-height: $size-font-large;
    color: $red;
    text-align: center;
    font-size: $size-font;
    margin-top: $size-6xs;
    overflow: hidden;
    box-sizing: border-box;
}