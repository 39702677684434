@use 'components/shared/core.scss' as *;

.input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-tap-highlight-color: transparent;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &.horizontal {
        flex-direction: row;
    }
    &.error {
        .input-display {
            border: 1px solid $red;
        }
        .input-cover-display {
            border: 1px solid $red;
        }
    }
    &.disabled {
        .input-display, .input-cover-display {
            background-color: $grey-lighter;
            color: $grey;
            pointer-events: none;
        }
    }
    &.display-only {
        pointer-events: none;
        .input-display {
            background-color: $white;
            color: $primary-color-darker;
            cursor: default;
        }
        .input-cover-display {
            background-color: $white;
            border-color: transparent;
        }
    }
    &.right {
        .input-display, .input-cover-display {
            text-align: right;
        }
    }
}

.input-label {
    height: $size-xxs;
    min-height: $size-xxs;
    padding-left: $size-5xs;
    margin-left: $size-4xs;
    margin-bottom: $size-6xs;
    font-size: $size-font;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    color: $primary-color-darker;
    cursor: default;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-word;
    box-sizing: border-box;
}

.input-mask {
    position: absolute;
    overflow: hidden;
    top: calc($size-xs - 3px); 
    left: 0;
    right: 0;
    height: $input-height;
    width: 100%;
    line-height: $size-mid; 
    padding-left: $size-3xs; 
    padding-right: $size-3xs; 
    border: none;
    border-radius: $radius-small; 
    box-sizing: border-box;
    opacity: 1;
    z-index: 3;
    background-color: $grey-mask;
    transition: opacity 0.3s ease-out;
    cursor: default;
    &.fading-mask {
        opacity: 0;
    }
    &.faded {
        opacity: 0;
        z-index: -1; 
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 40%; 
        height: 500%; 
        pointer-events: none; 
        background-color: $mask-wipe;
        z-index: 3; 
        filter: blur($size-3xs); // Assuming $size-3xs is defined
        animation: ripple-mask-move 1.4s linear infinite;
        transition: opacity 0.1s ease-out;
        transform: translateY(-50%) rotateZ(45deg); // Rotate the element to move diagonally

    }
}

.input-mask.fading-pseudo::before {
    opacity: 0;
}

.input-display {
    height: $input-height;
    font-size: $size-font;
    line-height: $size-mid;
    outline: none;
    border-radius: $radius-small;
    padding-left: $size-3xs;
    padding-right: $size-3xs;
    font-size: $size-font;
    line-height: $size-mid;
    text-align: left;
    background-color: $off-white;
    color: $primary-color-darker;;
    border: 1px solid transparent;
    transition: all .1s;
    cursor: pointer;
    overflow:hidden;
    text-overflow: ellipsis;
    z-index: 1;
    opacity: 0;
    box-sizing: border-box;
    &.placeholder {
        color: $grey-dark;
    }
    &.allCaps {
        text-transform: uppercase;
    }
    &.error{
        border: 1px solid $red;
    }
    &::selection {
        background-color: $secondary-color-light;
    }
    &:focus {
        opacity: 1; 
        border: 1px solid $secondary-color;
    }
    
    &.no-cover{
        opacity: 1;
    }
}

::-ms-reveal {
    display: none
}

.input-password-reveal-button, .input-search-icon {
    position:absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: $size-4xs;
    top: calc($size-xxs + $size-5xs);
    width: $size-font-xl;
    height: $size-mid;
    border: 1px solid transparent;
    box-sizing: border-box;
    z-index: 2;
    .icon {
        width: $size-xxs;
        height: $size-xxs;
        svg {
            height: $size-xxs;
            width: $size-xxs;
        }
    }
}

.input-error-message {
    height: $size-font-large;
    max-height: 0;
    line-height: $size-font-large;
    color: $red;
    text-align: center;
    font-size: $size-font;
    margin-top: $size-6xs;
    margin-bottom: $size-font-large;
    overflow: hidden;
    box-sizing: border-box;
    &.show {
        max-height: $size-font-large;
        margin-bottom: 0;
    }
}

.input-cover-display {
    position: absolute;
    overflow: hidden;
    top: calc($size-xs - 3px); 
    left: 0;
    right: 0;
    height: $input-height;
    width: 100%;
    font-size: $size-font;
    line-height: $size-mid;
    text-align: left;
    line-height: $size-mid; 
    padding-left: $size-3xs; 
    padding-right: $size-3xs; 
    border: 1px solid $grey-lightest;
    border-radius: $radius-small; 
    box-sizing: border-box;
    overflow:hidden;
    text-overflow: ellipsis;
    opacity: 1;
    z-index: 0;
    cursor: pointer;
    background-color: $off-white; 
    &.placeholder {
        color: $grey;
    }
}

@keyframes ripple-mask-move {
    0% {
        left: -70%;
    }
    100% {
        left: 100%;
    }
}

.input-container.hide-label {
    .input-label {
        display: none;
    }
    .input-display {
        margin: 0px;
    }
    .input-mask, .input-cover-display {
        top: 0px;
    }
    .input-password-reveal-button {
        top: 0;
    }
}

.input-container.hide-errors {
    .input-error-message {
        display: none;
    }
}

.input-container.text-center {
    .input-display{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0px;
        text-align: center;
    }
    .input-cover-display {
        text-align: center;
    }
}

.input-container-only {
    pointer-events: none;
    .input-display {
        background-color: $white;
        color: $primary-color-darker;;
    }
}

.input-container.masked {
    cursor: default;
}

.input-container.overflow {
    .input-display {
        justify-content: flex-end;
    }
}

@keyframes autofill {
    0% {background-color: transparent;}
    100% {background-color: transparent;}
}
  
input:-webkit-autofill {
    animation-name: autofill;
    animation-duration: 0.05s;
    animation-delay: 0s;
}