@use 'components/shared/core.scss' as *;

.device-details-finances-card {
    .card-content {
        min-height: 305px; // just enough to match current Status + Assignment cards
    }
}

.device-status-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: $size-xxs;
    text-align: left;
    font-weight:bold;
    margin-left: $size-xs;
    font-size: $size-font;
    color: $primary-color-darkest;
}

.device-status-result {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: $size-xxs;
    line-height: $size-font;
    text-align: center;
    font-size: $size-font;
    font-weight: bold;
    color: $red;
    &.success {
        color: $green;
    }
}

.device-commission-button {
    min-width: $size-5xl;
}

.device-details-label {
    font-weight: bold;
    margin-bottom: $size-6xs;
}

.device-cash-section-label {
    margin-top: $size-xxs;
    text-align: center;
    font-size: $size-xs;
}

.device-details-value {
    text-align: end;
}

.assignment-top.card {
    margin-top: $size-xxs;
}
